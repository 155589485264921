.sidebar {
    border: 2px solid grey;
    background-color: #262626;
    min-height: 200px;
    min-width: 150px;
    max-width: 150px;
    padding: none;
}

.collapsed {
    background-color: #262626;
    min-height: 200px;
    width: 0px;
    transition: 0.2s;
    overflow: hidden;
    transition: width 0.3s ease-out;
}

.bar {
    box-sizing: border-box;
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    padding: 0.25rem;
}

.button {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    height: 66px;
}

.page li {
    border-left: 1px solid grey;
    border-right: 1px solid grey;
    padding: 0 1rem;
    margin-bottom: 0;
}

.page {
    border-bottom: 1px solid grey;
}