/* Imports */

@font-face {
    font-family: "Inconsolata";
    src: url("./fonts/Inconsolata-Regular.ttf");
}

@font-face {
    font-family: "Source Code Pro";
    src: url("./fonts/SourceCodePro-Regular.ttf");
}

@font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat-Regular.ttf");
}

/* Text Rules */

h1,
h2,
h3,
h4 {
    font-family: "Montserrat";
}

h1 {
    font-size: 7rem;
    padding: 0px;
    margin: 0px;
}

h2 {
    font-size: 5rem;
    padding: 0px;
    margin: 0px;
}

h3 {
    font-size: 3rem;
    text-decoration: underline;
    margin-top: 0;
}

h4 {
    font-size: 2rem;
}

p {
    font-size: 1rem;
}

a {
    text-decoration: none;
    font-weight: bold;
    color: #855dce;
}

a:hover {
    text-decoration: underline;
}

a:active {
    text-decoration: underline;
    color: white;
}

.montserrat {
    font-family: "Montserrat";
}

.arial {
    font-family: Arial;
}

.source-code-pro {
    font-family: "Source Code Pro";
    letter-spacing: 1px;
}

.inconsolata {
    font-family: "Inconsolata";
}


/* Header */

header {
    background-color: #101010;
    margin: 0px;
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    border-bottom: solid 1px white;
    /*box-shadow: 0 0 8px 0px white;*/
    display: flex;
    justify-content: space-between;
    font-family: 'Inconsolata';
    height: 50px;
    align-items: center;
}

header nav {
    box-sizing: border-box;
    display: inline-flex;
    width: 100%;
    height: 50px;
    justify-content: space-between;
    padding: 0px 10px;
    text-align: center;
}

ul {
    width: 100%;
    display: flex;
    justify-content: space-around;
    list-style-type: none;
    font-size: 1.25rem;
    align-items: center;
}

/* Content */

body {
    background-color: #101010;
    display: block;
    color: white;
    padding: 0px;
    margin: 0px;
    justify-content: center;
    background-image: url("./imgs/backPattern.png");
    background-attachment: fixed;
    background-repeat: no-repeat;
    transition: 0.1s;
}

.banner {
    display: flex;
    justify-content: center;
    padding: 45px;
}

.banner-content {
    line-height: 5rem;
}

.content-case {
    display: flex;
    justify-content: center;
}

.content {
    font-family: Arial, Helvetica, sans-serif;
    padding: 10px 20px;
    display: block;
    max-width: 1500px;
}

.container {
    border: 1px solid white;
    box-shadow: 0 0 5px 0px white;
    margin-bottom: 3rem;
    padding: 15px;
    background-color: #101010;
    display: block;
    min-width: 50rem;
}

.space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.5rem;
}

.center {
    display: flex;
    justify-content: center;
}

.icon {
    filter: invert(100%);
}

button {
    color: white;
    background-color: #101010;
    border: solid 1px white;
    padding: 0.5rem;
    border-radius: 0.5rem;
}

/* Footer */

footer {
    background-color: #101010;
    position: fixed;
    box-sizing: border-box;
    bottom: 0;
    width: 100%;
    display: flex;
    padding: 5px;
    border-top: solid 1px white;
    margin: 0px;
    justify-content: space-between;
    transition: 0.1s;
}

.footer-name {
    display: flex;
    align-self: last baseline;
}

.footer-year {
    display: flex;
    align-self: last baseline;
}

.footer-year p {
    padding-left: 152px;
}

footer p {
    font-family: "Inconsolata", Arial, serif;
    display: inline;
    margin-bottom: 0px;
}

/* Mobile Screens */

@media only screen and (max-width: 900px) {

    h1 {
        font-size: 4.5rem;
        padding: 0px;
        margin: 0px;
    }

    h2 {
        line-height: 1.25rem;
        font-size: 3rem;
        padding: 0px;
        margin: 0px;
    }

    h3 {
        font-size: 3rem;
    }

    h4 {
        font-size: 2rem;
    }

    p {
        font-size: 1rem;
    }

    /* Header */

    header {
        margin: 0px;
        width: 100%;
        padding: 0;
        height: 40px;
    }

    header nav {
        height: 40px;
        padding: 0px 10px;
    }

    ul {
        width: 100%;
        font-size: 1rem;
        margin-block-start: 0em;
        padding-inline-start: 0px;
    }

    header ul {
        margin-block-end: 0;
    }

    /* Content */

    body {
        padding: 0px;
        margin: 0px;
        transition: 0.1s;
    }

    .banner {
        padding: 1rem;
        padding-bottom: 2.5rem;
    }

    .banner-content {
        line-height: 5rem;
    }

    .content {
        padding: 0;
        width: 100%;
    }

    .container {
        margin-bottom: 4rem;
        padding: 15px;
        border-left: none;
        border-right: none;
        max-width: 100%;
        min-width: 0;
        box-shadow: none;
    }

    .space-between {
        margin: 0.5rem;
    }



    /* Footer */

    footer {
        position: fixed;
        box-sizing: border-box;
        bottom: 0;
        width: 100%;
        display: flex;
        padding: 5px;
        margin: 0px;
        justify-content: space-between;
        transition: 0.1s;
    }


    .footer-year p {
        padding-left: 0px;
    }
}