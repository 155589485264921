.board {
  display: block;
}

.boardRow {
  display: flex;
}

.button {
  height: 36px;
  width: 36px;
  border: 1px solid #10101000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.square {
  background: #dfdfdf;
  color: black;
  border-radius: 0.125rem;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 34px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 34px;
}

.boardRow:after {
  clear: both;
  content: '';
  display: table;
}